import Mockup from '../../../src/assets/images/products/mockups/smartmobilityplanner.png';
import Photo1 from '../../../src/assets/images/products/shots/smop-outside-1.jpg';
import Photo2 from '../../../src/assets/images/products/shots/smop-outside-2.jpg';
import Photo3 from '../../../src/assets/images/products/shots/smop-outside-3.jpg';
import Photo4 from '../../../src/assets/images/products/shots/smop-outside-4.jpg';
import Photo5 from '../../../src/assets/images/products/shots/smop-outside-5.jpg';

import LogoTEC from '../../../src/assets/images/clients/tec.svg';
import LogoSNCB from '../../../src/assets/images/clients/nmbs-sncb.svg';
import LogoDeLijn from '../../../src/assets/images/clients/delijn.svg';
import LogoSTIB from '../../../src/assets/images/clients/stib-mivb.svg';


export const data = {
  pageTitle: 'Services & APIs',
  productName: 'Services & APIs',
  title: 'Build your very own <br/>mobility <span class="focus-main">companion</span>.',
  mission: {
    title: 'The mobility of tomorrow,<br/>right in your <span class="focus-main">pocket</span>.',
    content: 'Discover SmartMobilityPlanner: our fully capable intermodal and real-time route planner is ready to guide you through your journey.<br/><br/>Wanna take back control over your mobility? Planning, routing, ticketing. We\'ve got your back.',
    src: Mockup,
    parallax: [-64, -10],
    buttons: [
      { title: 'Live Demo', href: 'https://smartmobilityplanner.be', target: '_blank' },
      { title: 'Try it for yourself', href: '#intercom' },
    ],
  },
  logos: [
    {
      logo: LogoSNCB,
      link: 'https://www.belgiantrain.be/fr/',
      name: 'SNCB-NMBS',
    },
    {
      logo: LogoSTIB,
      link: 'http://www.stib-mivb.be',
      name: 'STIB',
    },
    {
      logo: LogoTEC,
      link: 'https://www.letec.be/',
      name: 'Le Tec',
    },
    {
      logo: LogoDeLijn,
      link: 'https://www.delijn.be/fr/',
      name: 'De Lijn',
    },
  ],
  features: [
    {
      title: '<span class="focus-main">All-in-one</span>,<span class="focus-main"> Intermodal</span><br/>& <span class="focus-main">Real-time</span>.',
      content: 'We natively integrate all mobility solutions available out there. We combine them in the most efficient way. All that in real-time of course.',
      src: Photo1,

    },
    {
      title: 'Robust foundations,<br/>with your <span class="focus-main">custom UI</span>.',
      content: 'Our micro-services architecture allows us to customize the deployment to your specific needs. And we top it up with a tailor-made interface.',
      src: Photo2,
    },
    {
      title: 'Numerous <span class="focus-main">Ticketing Provider</span> supported.',
      content: 'E-ticketing is at the vanguard of the mobility of tomorrow. We have the expertise to make the dream come true.',
      src: Photo3,
    },
    {
      title: 'A singular <span class="focus-main">Belgian</span> flavour.',
      content: 'Native multilanguage support and results well-anchored in the Belgian mobility reality.',
      src: Photo4,
    },
    {
      title: 'Backed by a strong <span class="focus-main">Open Source</span> philosophy',
      content: 'As an active member of the open-source community, we shared most of our work freely. Just one more way we are contributing to building a greener future.',
      src: Photo5,
      buttons: [{ title: 'Check GitHub', href: 'https://github.com/nextmoov/nextmoov-smop-general' }],
    },
  ],
  callToAction:
  {
    title: 'Need a tailor-made solution?<br/>We\'re here.',
    content: 'Do you need to integrate route planning capabilities in your app? Or maybe only transit ticketing solutions? Our technological stack can do it all. Reach out, so we can schedule a demo.',
    buttons: [{ title: 'Get in Touch', href: '#intercom' }],
  },
};

export default data;
