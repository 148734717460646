import Mockup from '../../../src/assets/images/products/mockups/mobiscore-desktop-1.png';

export const data = {
  pageTitle: 'MobiScore, Realtime Mobility Analysis',
  productName: 'MobiScore',
  title: 'Realtime<br/><span class="focus-accent">Mobility</span> Analysis',
  mission: {
    content: 'How many Belgians can reach your store within 5 minutes?<br/>What is the most congested street in your city?<br/>Where to move your office to ease access to your employees?<br/><br/>MobiScore is your <span class="focus-accent">Mobility Survey Self-Service.</span> To answer all these questions, and so many more.',
    src: Mockup,
    parallax: [-90, 0],
  },
  keyFeatures: [
    {
      title: 'A Comprehensive<br/><span class="focus-main">Toolbox</span>',
      content: 'Configure and order your studies in a few clicks. Navigate through your past reports and dig into your data. Think of our MobiScore as <span class="focus-accent">your personal mobility consultancy service.</span>',

    },
    {
      title: '<span class="focus-main">New</span> &<span class="focus-main"> Old</span> Mobility<br/>Combined',
      content: 'We want you to stay relevant to those you care for. That\'s why we integrated <span class="focus-accent">all mobility players.</span> From Public Transport Operators (PTOs) to micro-mobility.<br/>And even your good ol\' private car.',
    },
    {
      title: 'The Versatility of<br/><span class="focus-main">Big Data</span>',
      content: 'We put together dozens of datasources and we use millions of datapoints to feed our studies. The result: on-the-fly granularity and configuration options. Rest assured: we\'ll match your objectives at a <span class="focus-accent">fair price.</span> ',
    },
  ],

  cases: [
    {
      title: 'MobiScore for Businesses',
      content: 'Analyze your sales and distribution networks and maximize its reach.<br/>Stay relevant to your employees while promoting greener mobility.<br/><br/>Discover MobiScore and leverage all the opportunities greener mobility has to offer.',
      buttons: [{ title: 'For Businesses', href: '/mobiscore/enterprise' }],

    },
    {
      title: 'MobiScore for Cities',
      content: 'Analyze your transit network and maximize its impact.<br/>Understand how people move around and prioritize what to do next.<br/><br/>Discover MobiScore and leverage all the opportunities greener mobility has to offer.',
      buttons: [{ title: 'For Cities', href: '/mobiscore/cities', theme: 'secondary' }],
    },
  ],

  endingQuote: 'Mobility is changing.<br/><span class="focus-main">Understand</span> that change.',
  callToAction:
    {
      title: 'Wondering what it looks like?',
      content: 'Get in touch and we\'ll be happy to help you see how to support your first concrete use case.',
      buttons: [{ title: 'Free Trial', href: '#intercom' }],
    },
};

export default data;
