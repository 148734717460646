import Mockup from '../../../src/assets/images/products/mockups/smartdisplays.png';
import Photo1 from '../../../src/assets/images/products/shots/smartdisplays-micromob-1.jpg';
import Photo2 from '../../../src/assets/images/products/shots/smartdisplays-station-1.jpg';
import Photo3 from '../../../src/assets/images/products/shots/smartdisplays-kiosk.jpg';

import LogoTEC from '../../../src/assets/images/clients/tec.svg';
import LogoSNCB from '../../../src/assets/images/clients/nmbs-sncb.svg';


export const data = {
  pageTitle: 'Digital Signage',
  productName: 'SmartKiosks & Displays',
  title: 'Surrounding Mobility<br/>at a <span class="focus-accent">glance</span>',
  mission: {
    title: 'All the mobility around<br/>right in front of <span class="focus-main">your eyes</span>.',
    content: 'What time is the next bus?<br/>Is there any delay?<br/>Can I find a bike nearby?<br/><br/>Faster than any app, and without requiring any device, our SmartDisplays answer these questions and tons of others.',
    src: Mockup,
    parallax: [-84, 0],
    buttons: [
      { title: 'Try it for yourself', href: '#intercom' },
    ],

  },
  logos: [
    {
      logo: LogoTEC,
      link: 'https://www.letec.be/',
      name: 'Le Tec',
    },
    {
      logo: LogoSNCB,
      link: 'https://www.belgiantrain.be/fr/',
      name: 'SNCB-NMBS',
    },
  ],
  features: [
    {
      title: 'Designed for <br/>the <span class="focus-main">new mobility</span>.',
      content: 'Thanks to our microservices architecture, we add new mobility providers every day. Unlike old industrial solutions, our SmartDisplays were designed for the latest forms of mobility: shared bikes, shared cars, shared everything. Without forgetting our good ol\' transit networks!',
      src: Photo1,

    },
    {
      title: '<span class="focus-main">Easily</span> setup and deployed.',
      content: 'Whether you already have a digital signage solution<br/>and just want to plug ours on, or whether you wish we do it all — installation & hardware: We do it all! One screen — like we did for BECi — or an industrial country-wide deploy like we did in Wallonia for TEC: SmartDisplays are designed to support any scale of deployment.',
      src: Photo2,
    },
    {
      title: 'Dynamic & <span class="focus-main">Interactive</span>.',
      content: 'SmartDisplays also come in a “Kiosk-like flavour”: on top of the non-interactive digital signage solution, SmartDisplays can also be fully interactive. It includes killer features like Real-Time route planning, live network disruptions alerts, live maps,... ',
      src: Photo3,
    },
  ],
  endingQuote: 'Surrounding mobility <br/>right in front of <span class="focus-main">your eyes</span>.',
  callToAction:
  {
    title: 'Wondering what it looks like?',
    content: 'Get in touch and we\'ll be happy to help you see how to support your first concrete use case.',
    buttons: [{ title: 'Schedule a Demo', href: '#intercom' }],

  },
};

export default data;
