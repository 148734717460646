import Mockup from '../../../src/assets/images/products/mockups/mobiscore-desktop-3.png';
import Photo1 from '../../../src/assets/images/products/parts/mobiscore-map-viz.jpg';
import Photo2 from '../../../src/assets/images/products/parts/mobiscore-list.jpg';

export const data = {
  pageTitle: 'MobiScore, Realtime Mobility Analysis',
  productName: 'MobiScore for Companies',
  title: 'Realtime<br/><span class="focus-accent">Mobility</span> Analysis',
  mission: {
    title: 'We help <span class="focus-main">companies</span> leverage <br /> the mobility of tomorrow.',
    content: 'How many Belgians can reach your store within 5 minutes?<br/>How to expand your sales network in a sustainable fashion?<br/>Where to move your office to ease access to your employees?<br/><br/>MobiScore is your <span class="focus-accent">Mobility Survey Self-Service</span>. To answer all these questions, and so many more.',
    src: Mockup,
    parallax: [-90, 0],
    buttons: [
      { title: 'Free Trial', href: '#intercom' },
    ],
  },

  keyFeatures: [
    {
      title: '<span class="focus-main">Instantaneous</span> & <span class="focus-main">Realtime</span><br/>Insights',
      content: 'Click-Click-Click.<br/>That\'s all it takes to generate your study in MobiScore.<br/>It\'s all <span class="focus-accent">Self-Service.</span>',
    },
    {
      title: '<span class="focus-main">Flexible</span> & <span class="focus-main">Transparent</span> Pricing',
      content: 'Mobility solutions to watch. Radius of observations. Granularity of data.<br/>Choose it all and get a <span class="focus-accent">fair transparent quote.</span>',
    },
    {
      title: '<span class="focus-main">Static</span> & <span class="focus-main">Dynamic</span><br/>Studies',
      content: 'Static studies offer <span class="focus-accent">data-driven insights</span> backed up by thousands of historical data points. Dynamic studies provide real-time impact and <span class="focus-accent">A/B comparisons </span>of any situation.',
    },
  ],

  features: [
    {
      title: 'A complete overview of your <span class="focus-main">mobility</span>',
      content: 'Where should you build your next store? How distributed are your shops? What would be the perfect location for your new HQ?<br/><br/>Our Overview Map allows you to comprehend your Mobility Situation in one look.',
      src: Photo1,
    },
    {
      title: 'Tailor-made details of your specific case',
      content: 'Our interactive TableView allows you to deep dive into the data. Shop-By-Shop or Employee-by-Employee:<br/>Get a complete understanding of what\'s going on at every location. And get insights on what to do next.',
      src: Photo2,
    },
  ],
  cases: [
    {
      title: 'MobiScore for Cities',
      content: 'Analyze your transit network and maximize its impact.<br/>Understand how people move around and prioritize what to do next.<br/><br/>Discover MobiScore and leverage all the opportunities greener mobility has to offer.',
      buttons: [{ title: 'For Cities', href: '/mobiscore/cities' }],
    },
  ],
  endingQuote: 'Mobility is changing.<br/><span class="focus-main">Understand</span> that change.',
  callToAction:
    {
      title: 'What would be the result for you?',
      content: 'Curious about what a MobiScore analysis would reveal for your company?<br/><br/>Reach out so we can setup a free demo for your specific case in no-time.',
      buttons: [{ title: 'Free Trial', href: '#intercom' }],

    },
};

export default data;
