import React from 'react';

import LayersManager from 'stoomlink-commons-ui/LayersManager';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import BodyText from 'stoomlink-commons-ui/BodyText/BodyText';
import Title from 'stoomlink-commons-ui/Title';

import Container from '../layouts/Container';
import Layout from '../layouts/Layout';
import Head from '../layouts/Head';

import Button from '../components/Button';
import TextAndPicture from '../components/TextAndPicture';
import LogoWall from '../components/LogoWall';
import List from '../components/List';
import Footer from '../components/Footer';
import FloatingParagraph from '../components/FloatingParagraph';
import Card from '../components/Card';
import ActionCard from '../components/ActionCard';
import Hero from '../components/Hero';
import Quote from '../components/Quote';
import FeatureBlock from '../components/FeatureBlock';

// =============================================================================
// Content
// =============================================================================
import mobiscore from '../../content/products/mobiscore';
import mobiscoreEnterprise from '../../content/products/mobiscore/enterprise';
import mobiscoreCities from '../../content/products/mobiscore/cities';
import smartdisplays from '../../content/products/smartdisplays';
import smartmobilityplanner from '../../content/products/smop';
import broadcast from '../../content/products/broadcast';

const pagesBySlugs = {
  smartdisplays,
  mobiscore,
  'mobiscore/enterprise': mobiscoreEnterprise,
  'mobiscore/cities': mobiscoreCities,
  smartmobilityplanner,
  broadcast,
};
// =============================================================================
// Page
// =============================================================================

const ProductPage = ({ pageContext: { slug } }) => {
  const data = pagesBySlugs[slug];
  const {
    pageTitle,
    productName,
    title, summary,
    mission,
    keyFeatures,
    features,
    cases,
    endingQuote,
    logos,
    callToAction,
  } = data;

  return (
    <ThemeProvider themeStyle="light">
      <LayersManager>
        <Layout>
          <Head title={pageTitle} />
          <Hero suptitle={productName} title={title}>
            {(summary && mission.content)
              && (
                <div dangerouslySetInnerHTML={{
                  __html: summary,
                }}
                />
              )}
          </Hero>

          <TextAndPicture
            src={mission.src}
            parallax={mission.parallax}
          >
            <FloatingParagraph
              title={mission.title}
              buttons={mission.buttons}
            >
              {mission.content}
            </FloatingParagraph>
          </TextAndPicture>
          {keyFeatures && (
            <Container>
              <List items={keyFeatures} />
            </Container>
          )}
          {features && (
            <>
              {features.map((product, i) => {
                const {
                  title: featuresTitle,
                  content, src,
                  buttons,
                } = product;
                return (
                  <FeatureBlock
                    key={`${featuresTitle + i}`}
                    title={featuresTitle}
                    src={src}
                    buttons={buttons}
                  >
                    <BodyText
                      dangerouslySetInnerHTML={{
                        __html: content,
                      }}
                      type="eyebrow"
                    />
                  </FeatureBlock>
                );
              })}
            </>
          )}
          {logos && (<LogoWall logoWall={logos} />)}

          {(cases && cases.length >= 3 && (
            <Container>
              <List items={cases} theme="secondary" />
            </Container>
          ))}

          {(cases && cases.length >= 1 && cases.length <= 2) && (
            <Container>
              {cases.map((item, i) => {
                const {
                  title: cardTitle,
                  content, buttons,
                } = item;
                return (
                  <Card theme={(i % 2 === 0) ? 'primary' : 'accent'} key={`${cardTitle + i}`}>
                    <Title
                      dangerouslySetInnerHTML={{
                        __html: cardTitle,
                      }}
                      displayLevel={5}
                    />
                    <BodyText dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                    />
                    {buttons && (
                      <>
                        {buttons.map((button) => {
                          const { title: buttonTitle, href, theme } = button;
                          return (
                            <Button href={href} theme={theme} key={`${buttonTitle + i}`}>{buttonTitle}</Button>
                          );
                        })}
                      </>
                    )}
                  </Card>
                );
              })}
            </Container>
          )}

          <Container>
            {endingQuote && (<Quote>{endingQuote}</Quote>)}
          </Container>

          {callToAction && (
            <ActionCard
              title={callToAction.title}
              buttons={callToAction.buttons}
            >
              {callToAction.content}
            </ActionCard>
          )}
          <Footer />
        </Layout>
      </LayersManager>
    </ThemeProvider>
  );
};

export default ProductPage;
