import Mockup from '../../../src/assets/images/products/mockups/broadcast-2.png';
import Photo1 from '../../../src/assets/images/products/shots/broadcast-map-overview.jpg';
import Photo2 from '../../../src/assets/images/products/shots/broadcast-news-rtbf.png';
import Photo3 from '../../../src/assets/images/products/shots/broadcast-presenter-mode.jpg';

import LogoRTBF from '../../../src/assets/images/clients/rtbf.svg';

export const data = {
  pageTitle: 'Broadcast, mobility watcher tool for media',
  productName: 'Broadcast',
  title: 'Putting Mobility</span><br/>on the <span class="focus-accent">Front Page</span>',
  mission: {
    title: 'Toolset dedicated to <span class="focus-main">news professionals</span>.',
    content: 'Broadcast is our tool suite for media — Radio, TV, Newspapers. Broadcast makes Transit & Cars traffic report a breeze.',
    src: Mockup,
    parallax: [-74, 20],
    buttons: [
      { title: 'Schedule a Demo', href: '#intercom' },
    ],
  },
  logos: [
    {
      logo: LogoRTBF,
      link: 'https://www.rtbf.be/',
      name: 'RTBF',
    },
  ],
  features: [
    {
      title: 'The <span class="focus-main">pulse</span> of mobility.',
      content: 'Broadcast watches the entire green mobility network and alerts you when something goes wrong.',
      src: Photo1,

    },
    {
      title: 'Easy <span class="focus-main">integration</span>.',
      content: 'TV, website, app... Broadcast fits seamlessly into any existing newsfeed.',
      src: Photo2,
    },
    {
      title: 'Personalization & <span class="focus-main">Automation</span>.',
      content: 'Stay relevant to your audience. Configure and automate the newsfeed based on numerous parameters.',
      src: Photo3,
    },
  ],
  callToAction:
  {
    title: 'Get your personalised offer now',
    content: 'TV, Radio or Pure-Player Media: We\'d love to discuss with you how Broadcast can fit the specific needs of your redaction. Reach out so we can schedule a call!',
    buttons: [{ title: 'Schedule a Demo', href: '#intercom' }],

  },
};

export default data;
