import Mockup from '../../../src/assets/images/products/mockups/mobiscore-desktop-1.png';
import Photo1 from '../../../src/assets/images/products/parts/mobiscore-map-hq.jpg';
import Photo2 from '../../../src/assets/images/products/parts/mobiscore-dashboard.jpg';

export const data = {
  pageTitle: 'MobiScore, Realtime Mobility Analysis',
  productName: 'MobiScore for Cities',
  title: 'Realtime<br/><span class="focus-accent">Mobility</span> Analysis',
  mission: {
    title: 'We help <span class="focus-main">cities</span> accelerate the modal shift.',
    content: 'What is the most congested street in your city?<br/>How should you strengthen your transit network?<br/>How many Belgians can navigate your city using green mobility solutions?<br/><br/>MobiScore is your Mobility Survey Self-Service.<br/><br/>To answer all these questions, and so many more.',
    src: Mockup,
    parallax: [-90, 0],
    buttons: [
      { title: 'Free Trial', href: '#intercom' },
    ],
  },

  keyFeatures: [
    {
      title: '<span class="focus-main">Instant</span> & <span class="focus-main">Realtime</span><br/>Insights',
      content: 'Click-Click-Click.<br/>That\'s all it takes to generate your study in MobiScore.<br/>It\'s a <span class="focus-accent">Self-Service.</span>',
    },
    {
      title: '<span class="focus-main">Flexible</span> & <span class="focus-main">Transparent</span> Pricing',
      content: 'Mobility solutions to watch. Radius of observations. Granularity of data.<br/>Choose it all and get a <span class="focus-accent">fair transparent quote.</span>',
    },
    {
      title: '<span class="focus-main">Static</span> & <span class="focus-main">Dynamic</span><br/>Studies',
      content: 'Static studies offer <span class="focus-accent">data-driven insights</span> backed up by thousands of historical data points. Dynamic studies provide real-time impact and <span class="focus-accent">A/B comparisons</span> of any situation.',
    },
  ],

  features: [
    {
      title: 'Get your mobility situation<br/>at a single <span class="focus-main">glance</span>',
      content: 'Which areas in your cities are pretty well deserved by public transports? What are the black spots? Where is there room for improvement?<br/>Our Overview Map allows you to comprehend your situation in one look.',
      src: Photo1,
    },
    {
      title: 'Tailor-made details<br/>of your specific case',
      content: 'Our interactive TableView allows you to deep dive into the data. Point-by-Point, Street-by-Street, Zone-by-Zone:<br/>Get a complete understanding of what\'s going on at every location of your territory. And get insights on how to improve and fix any situation.',
      src: Photo2,
    },
  ],
  cases: [
    {
      title: 'MobiScore for Businesses',
      content: 'Analyze your sales and distribution networks and maximize its reach.<br/>Stay relevant to your employees while promoting greener mobility.<br/><br/>Discover MobiScore and leverage all the opportunities greener mobility has to offer.',
      buttons: [{ title: 'For Business', href: '/mobiscore/enterprise' }],
    },
  ],

  endingQuote: 'Mobility is changing.<br/><span class="focus-main">Understand</span> that change.',
  callToAction:
    {
      title: 'What would be the result for you?',
      content: 'Curious about what a MobiScore analysis would say about your city?<br/><br/>Reach out so we can setup a free demo for your specific case in no time.',
      buttons: [{ title: 'Free Trial', href: '#intercom' }],
    },
};

export default data;
